export const allEnvironmentConfig = [
  {
    hostprefix: ["dev", "localhost"],
    authorityUrl:
      "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    clientId: "00f2a91b-a71c-4f06-8d79-9022e51ebb00",
    ApiGatewayHost: "fd-mintstudioapi-dev.azurefd.net",
    // ApiGatewayHost: "onemintapiesdev.azure-api.net",
    CdnHostUrl: "https://fd-mintstudiomicrofrontends-dev.azurefd.net/",
    appInsightsEndpointUrl: "https://dc.services.visualstudio.com/v2/track",
    instrumentationKey: "1732c4db-a82a-43ca-813e-9a9a043d7403",
    enableUTP: false,
    cuppUsageApiClientId:
      "b2406a33-9114-4fa4-b366-ff1935cc625f/user_impersonation",
    defaultFiscalYear: "2021",
    environment: "Pre-Production",
    componentId: "4fe907f1-cbf1-40c7-9868-f6c1268298c6",
    componentName: "Mint Studio",
    serviceOffering: "Incentive Compensation",
    serviceLine: "Finance",
    service: "MSF IC Platform",
  },
  {
    hostprefix: ["uat", "uatw"],
    authorityUrl:
      "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    clientId: "00f2a91b-a71c-4f06-8d79-9022e51ebb00",
    ApiGatewayHost: "fd-mintstudioapi-uat.azurefd.net",
    CdnHostUrl: "https://fd-mintstudiomicrofrontends-uat.azurefd.net/",
    appInsightsEndpointUrl: "https://dc.services.visualstudio.com/v2/track",
    instrumentationKey: "0a8586cb-aff3-4211-af3b-a068c6609245",
    enableUTP: true,
    cuppUsageApiClientId:
      "b2406a33-9114-4fa4-b366-ff1935cc625f/user_impersonation",
    defaultFiscalYear: "2021",
    environment: "Pre-Production",
    componentId: "4fe907f1-cbf1-40c7-9868-f6c1268298c6",
    componentName: "Mint Studio",
    serviceOffering: "Incentive Compensation",
    serviceLine: "Finance",
    service: "MSF IC Platform",
  },
  {
    hostprefix: ["prod", "mintstudio"],
    authorityUrl:
      "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    clientId: "00f2a91b-a71c-4f06-8d79-9022e51ebb00",
    ApiGatewayHost: "fd-mintstudioapi-prod.azurefd.net",
    CdnHostUrl: "https://fd-mintstudiomicrofrontends-prod.azurefd.net/",
    appInsightsEndpointUrl: "https://dc.services.visualstudio.com/v2/track",
    instrumentationKey: "26b912ea-70b6-4243-b2a2-c03bbbbce13c",
    enableUTP: false,
    cuppUsageApiClientId:
      "b2406a33-9114-4fa4-b366-ff1935cc625f/user_impersonation",
    defaultFiscalYear: "2021",
    environment: "Production",
    componentId: "4fe907f1-cbf1-40c7-9868-f6c1268298c6",
    componentName: "Mint Studio",
    serviceOffering: "Incentive Compensation",
    serviceLine: "Finance",
    service: "MSF IC Platform",
  },
  {
    hostprefix: ["prod", "mintstudiosecondary"],
    authorityUrl:
      "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    clientId: "00f2a91b-a71c-4f06-8d79-9022e51ebb00",
    //ApiGatewayHost: "mintstudiogateway.azure-api.net",
    ApiGatewayHost: "fd-mintstudioapi-prod.azurefd.net",
    CdnHostUrl: "https://fd-mintstudiomicrofrontends-prod.azurefd.net/",
    appInsightsEndpointUrl: "https://dc.services.visualstudio.com/v2/track",
    instrumentationKey: "c8f29307-0a46-44b7-a290-b856648f5849",
    enableUTP: false,
    cuppUsageApiClientId:
      "b2406a33-9114-4fa4-b366-ff1935cc625f/user_impersonation",
    defaultFiscalYear: "2021",
    environment: "Production",
    componentId: "4fe907f1-cbf1-40c7-9868-f6c1268298c6",
    componentName: "Mint Studio",
    serviceOffering: "Incentive Compensation",
    serviceLine: "Finance",
    service: "MSF IC Platform",
  },
];

export const customEnvironmentConfig = process.env.API_URL
  ? {
      hostprefix: [],
      authorityUrl: process.env.AAD_LOGIN_URL + process.env.AAD_TENANT_ID,
      clientId: process.env.AAD_CLIENT_ID,
      scope: process.env.AAD_SCOPE,
      ApiGatewayHost: process.env.API_URL.replace("https://", ""),
      CdnHostUrl: process.env.CDN_HOST_URL,
      appInsightsEndpointUrl: process.env.AI_ENDPOINT,
      instrumentationKey: process.env.AI_KEY,
      enableUTP: false,
      defaultFiscalYear: "2021",
    }
  : null;
